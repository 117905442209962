import Vue from "vue";
import VueRouter from "vue-router";
import Form from "../views/Form.vue";
import Saved from "../views/Saved.vue";
import Changed from "../views/Changed.vue";
import Deleted from "../views/Deleted.vue";
import VueFilterDateFormat from "@vuejs-community/vue-filter-date-format";
import VueFilterDateParse from "@vuejs-community/vue-filter-date-parse";

Vue.use(VueFilterDateParse);
Vue.use(VueFilterDateFormat);
Vue.use(VueRouter);

console.log("Init Router");

const routes = [
  {
    path: "/",
    name: "Form",
    component: Form
  },
  {
    path: "/admin/ldgldsjfgirerljgr/homepage.html",
    name: "Form",
    component: Form
  },
  {
    path: "/#saved",
    name: "Saved",
    component: Saved
  },
  {
    path: "/#changed",
    name: "Changed",
    component: Changed
  },
  {
    path: "/#deleted",
    name: "Deleted",
    component: Deleted.vue
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function(to) {
    console.log(to);
    if (to !== undefined) {
      if (
        to.hash === "#app" ||
        to.hash === "#datenschutz" ||
        to.hash === "#impressum" ||
        to.hash === "#home"
      ) {
        return to.hash;
      }
      return {
        selector: "#home"
      };
    }
  }
});

export default router;
