<template>
  <div class="changed">
    <p>Eure Daten wurden geändert.</p>
    <p>
      Ihr erhaltet darüber
      <strong>keine</strong> Bestätigungsmail.
    </p>
  </div>
</template>

<script>
export default {};
</script>
