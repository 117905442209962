<template>
  <li class="coronazahl" :class="corona.status.toLowerCase()">
    <p>
      {{ corona.datum | dateParse("YYYY-MM-DD") | dateFormat("DD.MM.YYYY") }}
    </p>
    <p>Köln: {{ Math.floor(corona.koeln * 10) / 10 }}</p>
    <p>NRW: {{ Math.floor(corona.nrw * 10) / 10 }}</p>
  </li>
</template>

<script>
export default {
  props: {
    corona: Object
  }
};
</script>

<style>
.coronazahl {
  width: 100px;
  border: 1px solid #ccc;
  padding: 3px;
  margin: 0 10px;
}
.coronazahl p {
  margin: 0;
}
.rot {
  background-color: lightblue;
}
.gelb {
  background-color: lightblue;
}
.gruen {
  background-color: lightblue;
}
</style>
