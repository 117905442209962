<template>
  <div class="deleted">
    <p>Schade, dass Ihr nicht kommt.</p>
    <p>
      Eure Abmeldung wurde gespeichert. Ihr bekommt darüber
      <strong>keine</strong>
      Bestätigungsmail.
    </p>
  </div>
</template>

<script>
export default {};
</script>
