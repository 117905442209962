import axios from "axios";

const AXIOS = axios.create({
  baseURL: `${process.env.VUE_APP_SERVER}`,
  timeout: 5000
});

export default {
  getNextMilonga: function() {
    return AXIOS.get("/api/nextmilonga", {
      headers: {
        Authorization: "Bearer " + window.token
      }
    });
  },

  save: function(data) {
    return AXIOS.post("/api/saveinscription", data, {
      headers: {
        Authorization: "Bearer " + window.token
      }
    });
  },

  update: function(data) {
    return AXIOS.put("/api/saveinscription", data, {
      headers: {
        Authorization: "Bearer " + window.token
      }
    });
  },

  loadAnmeldung(id) {
    return AXIOS.get(`/api/inscription/${id}`, {
      headers: {
        Authorization: "Bearer " + window.token
      }
    });
  },

  delete(id) {
    return AXIOS.delete(`/api/inscription/${id}`, {
      headers: {
        Authorization: "Bearer " + window.token
      }
    });
  },

  getData(daten) {
    return AXIOS.post("/api/decrypt", daten, {
      headers: {
        Authorization: "Bearer " + window.token
      }
    });
  }
};
