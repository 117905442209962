<template>
  <div class="saved">
    <h1></h1>
    <p v-if="!warteliste">Ihr seid angemeldet.</p>
    <div v-else>
      <p>Ihr steht auf Position {{ position }} der Warteliste.</p>
      <p>
        Am Dienstag um 15 Uhr verschicken wir eine E-Mail an alle, die
        nachrücken können.
      </p>
    </div>
    <p>Eine Bestätigungsmail ist auf dem Weg zu Euch.</p>
    <p>
      Solltet Ihr Eure Pläne ändern, nutzt bitte den <a :href="'?id=' + id">Änderungslink</a> in der
      E-Mail.
    </p>
  </div>
</template>

<script>
export default {
  computed: {
    warteliste: function() {
      return this.$route.params.warteliste || false;
    },
    position: function() {
      return this.$route.params.position || 0;
    },
    id: function() {
      return this.$route.params.id || 0;
    }
  }
};
</script>
