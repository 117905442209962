<template>
  <div class="hidden" id="corona">
    <h3>Die aktuellen Corona-Inzindenzen in Köln und NRW</h3>
    <ul class="corona">
      <CoronaZahl v-for="c in corona" :key="c.datum" :corona="c" />
    </ul>
  </div>
</template>

<script>
import CoronaZahl from "@/components/CoronaZahl";
import backend from "@/api/backend.js";

export default {
  name: "Home",
  components: {
    CoronaZahl
  },
  mounted: function() {
    const _this = this;
    // Lade nächste Milonga
    backend
      .getNextMilonga()
      .then(function(response) {
        _this.milonga = response.data.milonga;
        _this.anmeldungStart = response.data.anmeldestart.replace("T", " ");
        _this.show = response.data.anmeldungMoeglich;
        _this.canceled = response.data.canceled;
        _this.anzahlAnmeldungen = response.data.anzahlAnmeldungen;
        _this.moeglicheAnzahlPaare = response.data.moeglicheAnzahlPaare;
        _this.corona = response.data.coronazahlen;
      })
      .catch(function(error) {
        if (error.response) {
          _this.zeigeFehlermeldung(
            "Ein Fehler ist beim Laden der nächsten Milonga aufgetreten. " +
              error.response.status
          );
        } else if (error.request) {
          _this.zeigeFehlermeldung(
            "Es gab keine Antwort vom Server beim Laden der nächsten Milonga."
          );
        } else {
          _this.zeigeFehlermeldung(error.message);
        }
        window.console.log(error);
      });
    if (window.location.search.indexOf("id=") > -1) {
      const uuid = this.findGetParameter("id");
      backend
        .loadAnmeldung(uuid)
        .then(function(response) {
          _this.model = response.data;
          _this.isChange = true;
        })
        .catch(function(error) {
          if (error.response) {
            _this.zeigeFehlermeldung(
              "Ein Fehler ist beim Laden Eurer Daten aufgetreten. " +
                error.response.status
            );
          } else if (error.request) {
            _this.zeigeFehlermeldung(
              "Es gab keine Antwort vom Server beim Laden Eurer Daten."
            );
          } else {
            _this.zeigeFehlermeldung(error.message);
          }
          window.console.log(error);
        });
    }
    ["colorme", "zeileeins", "zeilezwei"].forEach((myid) => {
      this.colorme(myid);
    });
  },
  methods: {
    findGetParameter: function(parameterName) {
      var result = null,
        tmp = [];
      location.search
        .substr(1)
        .split("&")
        .forEach(function(item) {
          tmp = item.split("=");
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
      return result;
    },
    getState: function(prop) {
      if (
        Object.keys(this.errors).length === 0 &&
        this.errors.constructor === Object
      ) {
        return null;
      }
      if (prop in this.errors) {
        return false;
      }
      return true;
    },
    zeigeFehlermeldung(afehlermeldung) {
      this.fehler = afehlermeldung;
      window.setTimeout(() => {
        const fehlermeldung = document.getElementById("fehlermeldung");
        const top = fehlermeldung.offsetTop - fehlermeldung.offsetHeight - 16;

        window.scrollTo({
          top: top,
          left: 0,
          behavior: "smooth"
        }),
          100;
      });
    },
    colorme(myid) {
      let colorme = document.getElementById(myid);
      if (colorme) {
        const text = colorme.textContent;
        colorme.textContent = "";
        for (let i = 0; i < text.length; i++) {
          const span = document.createElement("span");
          span.textContent = text.substring(i, i + 1);
          const r = Math.round(Math.random() * 255),
            g = Math.round(Math.random() * 255),
            b = Math.round(Math.random() * 255);
          span.setAttribute("style", `color: rgb(${r},${g},${b}`);
          colorme.appendChild(span);
        }
      }
    }
  },
  data: function() {
    return {
      milonga: "1970-01-01",
      anmeldungStart: "1970-01-01",
      show: true,
      corona: []
    };
  }
};
</script>

<style>
ul.corona {
  list-style-type: none;
  font-size: 0.8em;
  display: flex;
  padding: 0;
  margin: 0 -10px 10px -10px;
}
.hidden {
  display: none;
}
</style>
